.modal_fullscreen {
  width: 100vw;
  height: 100vh;
  margin: 0;
}
.modal_fullscreen .modal-content {
  height: 100vh;
}
.modal_fullscreen_body {
  width: 100%;
  height: 100%;
  margin: 0;
}