.icon_button_sm,
.icon_button_normal {
    border-radius: 50%;
    padding: 0.3rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
}
.icon_button_lg {
    font-size: 120%;
    border-radius: 50%;
    padding: 0.3rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
}
.icon_button_xl {
    font-size: 141%;
    border-radius: 50%;
    padding: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}