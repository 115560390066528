.calendar {
  min-height: 500px;
}
.fc-day-sat, .fc-day-sun {
    background-color:#ccc;
}
.fc-day-sat .fc-daygrid-day-number,
.fc-day-sun .fc-daygrid-day-number,
.fc-day-sat .fc-col-header-cell-cushion,
.fc-day-sun .fc-col-header-cell-cushion
{
    color:#fff !important;
}

.fc.fc-theme-standard td.fc-day-sat {
    border-left-width: 3px;
    border-left-style: solid;
    border-left-color: #999 !important;
    
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: #999 !important;
}
.fc.fc-theme-standard td.fc-day-sun {
    
    border-left-width: 3px;
    border-left-style: solid;
    border-left-color: #999 !important;
    
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: #999 !important;
    
    border-right-width: 3px;
    border-right-style: solid;
    border-right-color: #999 !important;
    
}
.fc.fc-theme-standard td.fc-day-sat.fc-day-disabled,
.fc.fc-theme-standard td.fc-day-sun.fc-day-disabled
{
    border-left-width: 0px;
    border-left-style: solid;
    border-left-width: #999;
    
    border-bottom-width: 0px;
    border-bottom-style: solid;
    border-bottom-width: #999;
}

.fc.fc-theme-standard .fc-col-header-cell.fc-day-sat,
.fc.fc-theme-standard .fc-col-header-cell.fc-day-sun
 {
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: #999 !important;
}

